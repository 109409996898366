<template>
  <h1>{{ msg }}</h1>
  <div class="container-fluid separator"></div>
  <div class="container-fluid">
    <div class="row justify-content-around">
      <div
        v-for="(image, index) in images"
        class="col d-flex justify-content-center"
        :key="index"
      >
        <img :src="image" alt="#" />
      </div>
    </div>
  </div>
</template>
<script>
// eslint-disable-next-line
import Firebase from "@/services/firebase.js";
import "bootstrap/dist/css/bootstrap.min.css";
// eslint-disable-next-line no-unused-vars
import bootstrap from "bootstrap";

export default {
  name: "HelloWorld",
  props: {},
  data() {
    return {
      msg: "Bienvenido",
      images: [],
    };
  },
  methods: {},
  created() {
    //http://localhost:8080/?page=cargill
    const page = new URL(location.href).searchParams.get("page").trim();
    const cargill = new Firebase(page);
    cargill.getDocument("trabajos", page).then((doc) => {
      this.msg = doc.titulo;
    });
    cargill.getAllBucket().then((file) => {
      file.items.forEach((item) => {
        cargill.getURL(item.fullPath).then((url) => {
          this.images.push(url);
        });
      });
    });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
img {
  width: 300px;
  margin: 40px;
}
</style>
