// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {
  getFirestore,
  collection,
  getDocs,
  doc,
  getDoc,
} from "firebase/firestore/lite";
import { getStorage, ref, getDownloadURL, listAll } from "firebase/storage";

export default class Firebase {
  constructor(folder) {
    //new Firebase("cargill")
    // TODO: Add SDKs for Firebase products that you want to use
    // https://firebase.google.com/docs/web/setup#available-libraries

    // Your web app's Firebase configuration
    // For Firebase JS SDK v7.20.0 and later, measurementId is optional
    this.firebaseConfig = {
      apiKey: "AIzaSyDExrWhi27dXKdwK_tbCYf-jDMc4v5f81k",
      authDomain: "proyectos-ghyasa.firebaseapp.com",
      projectId: "proyectos-ghyasa",
      storageBucket: "proyectos-ghyasa.appspot.com",
      messagingSenderId: "661180420131",
      appId: "1:661180420131:web:8e13ccb96ba0341eb16196",
      measurementId: "G-X86WPRG533",
    };
    // Initialize Firebase
    this.app = initializeApp(this.firebaseConfig);
    // eslint-disable-next-line
    this.analytics = getAnalytics(this.app);
    // Initialize Cloud Storage and get a reference to the service
    this.storage = getStorage(this.app);
    // Create a storage reference from our storage service
    // Points to the root reference
    this.storageRef = ref(this.storage);
    // Points to "cargill"
    this.imagesRef = ref(this.storageRef, folder);
    //database
    // eslint-disable-next-line
    this.db = getFirestore(this.app);
  }
  methodToBeDeleted() {
    // Points to "cargill/cargill_1.jpg"
    // Note that you can use variables to create child values
    let fileName = "cargill_1.jpg";
    let spaceRef = ref(this.imagesRef, fileName);
    // File path is "cargill/cargill_1.jpg"
    const path = spaceRef.fullPath;
    getDownloadURL(ref(this.storage, path)).then((url) => {
      console.log(url);
    });
    fileName = "cargill_2.jpg";
    spaceRef = ref(this.imagesRef, fileName);
    getDownloadURL(ref(this.storage, path)).then((url) => {
      console.log(url);
    });
  }
  getAllBucket() {
    return listAll(this.imagesRef);
  }
  getURL(path) {
    return getDownloadURL(ref(this.storage, path));
  }
  // Get a list of documents from your database
  async getDocuments(docName) {
    const Col = collection(this.db, docName);
    const colSnapshot = await getDocs(Col);
    const colList = colSnapshot.docs.map((doc) => doc.data());
    return colList;
  }
  async getDocument(colName, docName) {
    const docRef = doc(this.db, colName, docName);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      return docSnap.data();
    } else {
      // doc.data() will be undefined in this case
      return { titulo: "" };
    }
  }
}
